<template>
  <!-- NAME[epic=采购] 采购入库单 -->
  <div class="orderTest-container">
    <el-form ref="form" :model="form" inline>
      <el-form-item prop="operator_id">
        <el-select
          v-model="form.operator_id"
          clearable
          placeholder="请选择经办人"
          style="width: 180px"
        >
          <el-option
            v-for="(item, index) in jbrList"
            :key="index"
            :label="item.user_name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="supp_id">
        <el-select
          v-model="form.supp_id"
          clearable
          filterable
          placeholder="请选择供应商"
          style="width: 180px"
        >
          <el-option
            v-for="(item, index) in gysList"
            :key="index"
            :label="item.supp_name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="depot_id">
        <el-select
          v-model="form.depot_id"
          style="width: 180px"
          clearable
          placeholder="入库仓库"
        >
          <el-option
            v-for="(item, index) in ckList"
            :key="index"
            :label="item.depot_name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="bill_code">
        <el-input
          v-model="form.bill_code"
          placeholder="请输入订单号"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <goods-search
          ref="goodsSearch"
          :is-table="false"
          @select-goods-all="selectGoodsId"
        ></goods-search>
      </el-form-item>
      <el-form-item prop="pay_status">
        <el-select
          v-model="form.pay_status"
          clearable
          placeholder="结算状态"
          style="width: 140px"
        >
          <el-option
            v-for="(item, index) in pay_status"
            :key="index"
            :label="item.value"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="print_status">
        <el-select
          v-model="form.print_status"
          clearable
          placeholder="打印状态"
          style="width: 140px"
        >
          <el-option
            v-for="(item, index) in print_status"
            :key="index"
            :label="item.value"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width: 220px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item prop="remark">
        <el-input v-model="form.remark" placeholder="请输入备注"></el-input>
      </el-form-item>
      <el-form-item prop="is_closed">
        <el-checkbox v-model="form.is_closed">查看作废单据</el-checkbox>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleQuery">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="warning" @click="handleReset">重置</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleAdd">添加</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleExport">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table
      ref="table"
      v-loading="loading"
      stripe
      :data="list"
      show-summary
      :summary-method="summaryFunction"
    >
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <!-- <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template> -->
      </el-table-column>
      <!-- 选择框 -->
      <el-table-column
        align="center"
        type="selection"
        width="50"
      ></el-table-column>
      <el-table-column
        v-for="(item, index) in finallyColumns"
        :key="index"
        :prop="item.prop"
        :label="item.label"
        width="auto"
        align="center"
      >
        <template #default="{ row }">
          <div
            v-if="item.label == '单号'"
            class="underline"
            @click="handleCheck(row)"
          >
            {{ row[item.prop] }}
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop=""
        align="center"
        label="操作"
        fixed="right"
        min-width="150px"
      >
        <template #default="{ $index, row }">
          <div v-if="row.status_text == '已作废'">
            <el-button type="text" @click.native.prevent="handleCheck(row)">
              查看
            </el-button>
          </div>
          <div v-else>
            <el-button type="text" @click.native.prevent="handleCheck(row)">
              查看
            </el-button>
            <el-button
              type="text"
              @click.native.prevent="handleEdit($index, row)"
            >
              编辑
            </el-button>
            <el-button
              v-if="row.can_scrap == 1 && authbutton[3].is_check == 1"
              type="text"
              @click.native.prevent="handleDelete(row)"
            >
              作废
            </el-button>
            <!-- <el-button type="text" @click="handleCopy(row)">复制</el-button> -->
            <el-button
              type="text"
              @click.native.prevent="handlePrint($index, row)"
            >
              打印
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <check ref="check"></check>
    <add ref="add" @refresh="fetchData"></add>
    <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      bill_type="CG"
      :type="1"
      :data_id="data_id"
    ></ruilang-display-dialog>
  </div>
</template>
<script>
  import _ from 'lodash'
  import {
    jbrSelect,
    gysSelect,
    rkdList,
    purchaseDelete2,
  } from '@/api/purchase'
  import { getAllSelect2 } from '@/api/advanceOrder'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import Check from './components/check'
  import Add from './components/add'
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'
  import { downloadFile, postAction } from '@/api/Employee'
  import { day_n } from '@/utils/Time'
  import { getSelect } from '@/api/saleOrder'
  export default {
    name: 'PurchaseOrder2',
    components: {
      RuilangDisplayDialog,
      GoodsSearch,
      Check,
      Add,
    },
    data() {
      return {
        data_id: 0,
        loading: true,
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        jbrList: [],
        gysList: [],
        ckList: [],
        time: [],
        pay_status: [
          {
            id: 0,
            value: '未结清',
          },
          {
            id: 1,
            value: '已结清',
          },
        ],
        print_status: [
          {
            id: 0,
            value: '未打印',
          },
          {
            id: 1,
            value: '已打印',
          },
        ],
        form: {
          operator_id: '', //经办人id
          supp_id: '', //经销商id
          depot_id: '', //入库id
          bill_code: '', //单号
          goods_name: '', //商品名称或助记码
          goods_id: '',
          pay_status: '', //结算状态 0未结清 1已结清
          print_status: '', //打印状态  0未打印  1已打印
          start_time: '', //开始时间
          end_time: '', //结束时间
          remark: '', //备注
          is_closed: '', //是否 显示冲改作废单据  1是，0不是
          pageNo: 1, //当前页，默认1
          pageSize: 10, //每页条数，默认10
          sort: '', //排序字段，默认id
          order: '', //排序类型，默认asc
        },
        checkList: [
          '单号',
          '供应商',
          '入库仓库',
          '经办人',
          '采购金额',
          '制单时间',
          '结算状态',
          '备注',
          '打印',
        ],
        columns: [
          {
            order: 1,
            label: '单号',
            prop: 'bill_code',
            width: '',
          },
          {
            order: 2,
            label: '供应商',
            prop: 'supp_name',
            width: '',
          },
          {
            order: 3,
            label: '入库仓库',
            prop: 'depot_name',
            width: '',
          },
          {
            order: 4,
            label: '经办人',
            prop: 'operator',
            width: '',
          },
          {
            order: 5,
            label: '采购金额',
            prop: 'final_money',
            width: '',
          },
          {
            order: 6,
            label: '制单时间',
            prop: 'create_at',
            width: '',
          },
          {
            order: 7,
            label: '结算状态',
            prop: 'pay_status_text',
            width: '',
          },
          {
            order: 8,
            label: '备注',
            prop: 'remark',
            width: '',
          },
          {
            order: 9,
            label: '打印',
            prop: 'print_count',
            width: '',
          },
        ],
        list: [],
        xiaojia: [],
        authbutton: [],
      }
    },
    computed: {
      finallyColumns() {
        let arr = []
        this.checkList.forEach((item) => {
          arr.push(this.columns.filter((item2) => item2.label == item)[0])
        })
        return _.sortBy(arr, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    async created() {
      await this.initTime()
      await getSelect().then((res) => {
        // this.areaList = res.data.cust_area //区域
        this.authbutton = res.data.fun_list
      })
      // 经办人下拉
      await jbrSelect().then((res) => {
        this.jbrList = res.data.rows
      })
      // 供应商下拉
      await gysSelect().then((res) => {
        this.gysList = res.data.rows
      })
      // 仓库下拉
      await getAllSelect2().then((res) => {
        this.ckList = res.data
      })

      await this.fetchData()
    },
    mounted() {
      postAction('/purchaseAdmin/order/price-door').then((res) => {
        this.$refs.add.showBtn = res.data.door
      })
      // this.xiaoji()
      // window.addEventListener('resize', this.adjustWidth.bind(this))
    },
    methods: {
      handleExport() {
        const data = JSON.parse(JSON.stringify(this.form))
        delete data.pageNo
        delete data.pageSize
        downloadFile(
          '/purchaseAdmin/base/index-export',
          '采购入库单.xlsx',
          data
        )
      },
      initTime() {
        this.time = day_n(90)
        this.form.start_time = this.time[0]
        this.form.end_time = this.time[1]
        // console.log('day_n(90)', day_n(90))
      },
      async fetchData() {
        this.loading = true
        let { data, code, msg } = await rkdList(this.form)
        console.log(data)
        this.list = data.rows
        this.total = Number(data.total)
        this.loading = false
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      selectGoodsId(val) {
        this.form.goods_id = val.goods_id
      },
      // 表单按钮
      handleAdd() {
        this.$refs.add.isCopy = false
        this.$refs.add.showDialog = true
      },
      handleQuery() {
        this.form.pageSize = 10
        this.form.pageNo = 1
        this.fetchData()
      },
      handleReset() {
        this.clearForm()
      },
      clearForm() {
        this.$refs.form.resetFields()
        this.time = []
        this.$refs.goodsSearch.resetForm()
        this.form.goods_id = ''
      },
      // 表格操作
      handleCopy(row) {
        this.$refs.add.showDialog = true
        this.$refs.add.isCopy = true
        this.$refs.add.id = row.id
      },
      handleCheck(row) {
        this.$refs.check.isCopy = false
        this.$refs.check.id = row.id
        this.$refs.check.showDialog = true
      },
      handleDelete(row) {
        this.$confirm('确定作废此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            purchaseDelete2({
              id: row.id,
            }).then((res) => {
              this.$message.success('作废成功')
              this.fetchData()
            })
          })
          .catch(() => {
            console.log('no')
          })
      },
      handleEdit(index, row) {
        this.$refs.add.showDialog = true
        this.$refs.add.isCopy = true
        this.$refs.add.id = row.id
      },
      handlePrint(index, row) {
        this.data_id = row.id
        this.$refs.RuilangDisplayDialog.dialogFormVisible = true
      },
      // 表格合计fun
      summaryFunction(param) {
        const { columns, data } = param
        // 需要合计的下标
        let i = [6]
        let sums = []
        // let val = data.map((item) => {
        //   console.log(item)
        // })
        columns.forEach((col, idx) => {
          i.forEach((n) => {
            if (idx == n) {
              const val = data.map((item) => Number(item[col.property]))
              console.log(val)
              if (!val.every((value) => isNaN(value))) {
                const a = val.reduce((total, num) => {
                  if (!isNaN(total)) {
                    return total + num
                  }
                })
                sums[n] = a + ' 元'
              }
            }
          })
          if (idx == 0) {
            sums[idx] = '合计'
          }
        })
        console.log('sss', sums)
        return sums
      },
      getNum(prop) {
        // console.log('prop', prop)
        return prop
      },
      getXiaoji(name) {
        var sum = 0
        this.list.forEach((n, i) => {
          sum += parseFloat(n[name])
        })
        return sum
      },
    },
  }
</script>
<style lang="scss" scoped>
  .sum_footer {
    display: flex;
    display: -webkit-flex;
    line-height: 50px;
    color: #606266;
    background-color: #f5f7fa;
  }
  .sum_footer_unit {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    font-size: 14px;
    text-indent: 10px;
  }
  .sum_footer_unit.center {
    text-align: center;
  }
  .sum_footer.xiaoji {
    border-bottom: 1px solid #ebeef5;
  }
</style>
