<template>
  <el-dialog
    :modal="false"
    :close-on-click-modal="false"
    :title="title"
    top="5vh"
    width="1100px"
    :visible.sync="showDialog"
    fullscreen
    destroy-on-close
  >
    <div class="wrapper">
      <el-form
        ref="form"
        :model="form"
        inline
        label-position="right"
        label-width="90px"
      >
        <el-form-item label="供应商:" prop="supp_id">
          <el-select
            v-model="form.supp_id"
            clearable
            placeholder="请选择供应商"
            filterable
            remote
            :remote-method="remoteMethod"
            :loading="loadingS"
            style="width: 180px"
            @blur="gycBlur"
            @change="gysChange"
          >
            <el-option
              v-for="(item, index) in gysList"
              :key="index"
              :label="item.supp_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="经办人:" prop="operator_id">
          <el-select
            v-model="form.operator_id"
            clearable
            placeholder="请选择经办人"
            style="width: 180px"
          >
            <el-option
              v-for="(item, index) in jbrList"
              :key="index"
              :label="item.user_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="入库仓库:" prop="depot_id">
          <el-select
            v-model="form.depot_id"
            style="width: 180px"
            clearable
            placeholder="入库仓库"
          >
            <el-option
              v-for="(item, index) in ckList"
              :key="index"
              :label="item.depot_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <div></div>
        <el-form-item prop="">
          <el-input
            placeholder="使用扫码器添加商品"
            style="width: 303px"
          ></el-input>
        </el-form-item>
        <el-form-item label="" prop="minUnit">
          <el-checkbox v-model="minUnit">按小单位采购</el-checkbox>
        </el-form-item>
        <el-form-item label="" prop="maxUnit">
          <el-checkbox v-model="maxUnit">按大单位采购</el-checkbox>
        </el-form-item>
        <el-form-item prop="remark">
          <el-input
            v-model="form.remark"
            placeholder="备注"
            style="width: 303px"
          ></el-input>
        </el-form-item>
        <!-- 表格

         :summary-method="getSummaries"
          show-summary
           -->
        <el-table
          ref="tableSort"
          v-loading="loading"
          stripe
          :data="pageData"
          :summary-method="getSummaries"
          show-summary
          height="360px"
        >
          <!-- 序号 -->
          <el-table-column
            align="center"
            type="index"
            width="50"
          ></el-table-column>
          <el-table-column
            v-for="(item, index) in finallyColumns"
            :key="index"
            :label="item.label"
            :prop="item.prop"
            :width="item.width"
            align="center"
          >
            <!-- 类型 -->
            <template v-if="item.prop == 'goods_type'" #default="{ row }">
              <el-select
                v-model="row.goods_type"
                @change="goodsTypeChange(row)"
              >
                <el-option
                  v-for="(typeItem, index) in typeSelect"
                  :key="index"
                  :label="typeItem.value"
                  :value="typeItem.id"
                ></el-option>
              </el-select>
            </template>
            <!-- 商品名称 -->
            <template
              v-else-if="item.prop == 'goods_name'"
              #default="{ $index, row }"
            >
              <goods-search
                ref="goodsSearch"
                :auwidth="280"
                :f-key="row.goods_name"
                :brand-id="form.brand_id"
                :is-brand="form.supp_id == '' ? true : false"
                :show-kouwei="false"
                :depot-id="form.depot_id"
                @add-rows="addRows($event, $index, row)"
                @select-goods-all="selectGoods($event, row)"
              ></goods-search>
            </template>
            <!-- 单位 -->
            <template v-else-if="item.prop == 'unit_name'" #default="{ row }">
              <el-select
                v-model="row.unit_id"
                @change="selectUnit($event, row)"
              >
                <el-option
                  v-for="(unitItem, unitIndex) in row.arr_unit"
                  :key="unitIndex"
                  :label="unitItem.unit_name"
                  :value="unitItem.id"
                ></el-option>
              </el-select>
              <!-- {{ row.unit_name }} -->
            </template>
            <!-- 生产日期 -->
            <template
              v-else-if="item.prop == 'goods_production_date'"
              #default="{ row }"
            >
              <el-date-picker
                v-model="row.goods_production_date"
                type="date"
                placeholder="选择日期"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
                style="width: 180px"
              ></el-date-picker>
            </template>
            <!-- 采购价 -->
            <template v-else-if="item.prop == 'goods_price'" #default="{ row }">
              <div v-if="row.goods_type == 1">
                <el-input
                  v-model="row.goods_price"
                  @input="priceSum($event, row)"
                ></el-input>
              </div>
              <div v-else>0</div>
            </template>
            <!-- 采购数量 -->
            <template v-else-if="item.prop == 'goods_num'" #default="{ row }">
              <el-input
                v-model="row.goods_num"
                @input="priceSum($event, row)"
                @focus="inputfocus(row)"
              ></el-input>
            </template>
            <!-- 金额 -->
            <template v-else-if="item.prop == 'sum_money'" #default="{ row }">
              <div v-if="row.goods_type == 1">
                <el-input
                  v-model="row.sum_money"
                  @input="moneyChange($event, row)"
                ></el-input>
              </div>
              <div v-else>0</div>
            </template>
            <!-- 备注 -->
            <template v-else-if="item.prop == 'remark'" #default="{ row }">
              <el-input v-model="row.remark"></el-input>
            </template>
          </el-table-column>

          <el-table-column
            align="center"
            label="操作"
            fixed="right"
            width="100"
          >
            <template #default="{ $index, row }">
              <!-- <el-button type="text" @click.native.prevent="addRow(row)">
              添加
            </el-button> -->
              <el-button
                type="text"
                @click.native.prevent="copyRow($index, row)"
              >
                复制
              </el-button>
              <el-button
                type="text"
                @click.native.prevent="deleteRow($index, row)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :current-page="pageNo"
          :layout="layout"
          :page-size="pageSize"
          :total="computedTotal"
          background
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        ></el-pagination>
        <el-form-item>
          <el-checkbox
            v-if="showBtn"
            v-model="form.auto_update_price"
            :true-label="1"
            :false-label="0"
          >
            生成采购入库单时重新计算商品成本
          </el-checkbox>
        </el-form-item>
        <div class="pay">
          <div class="left">
            <el-form-item label="金额合计:" style="width: 100%">
              <el-input
                v-model="allSum"
                disabled
                style="width: 100px"
              ></el-input>
              <!-- <span style="margin-left: 10px">优惠:{{ yhl }}元</span> -->
            </el-form-item>
            <!-- <el-form-item label="优惠后应付:" style="width: 100%">
              <el-input
                v-model="form.final_money"
                style="width: 100px"
                @input="handleYhh"
              ></el-input>
            </el-form-item> -->
            <div style="position: relative">
              <div v-for="(payInfo, index) in pay_info" :key="index">
                <el-form-item label="付款金额" style="">
                  <el-input
                    v-model="payInfo.amount"
                    style="width: 140px"
                  ></el-input>
                </el-form-item>
                <el-form-item label="" style="">
                  <el-select
                    v-model="payInfo.account_id"
                    placeholder="付款账户"
                    style="width: 140px"
                  >
                    <el-option
                      v-for="(item, index) in fkzhList"
                      :key="index"
                      :value="item.id"
                      :label="item.name"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="" style="">
                  <el-select
                    v-model="payInfo.user_id"
                    placeholder="选择付款人"
                    style="width: 140px"
                  >
                    <el-option
                      v-for="(item, index) in fkrList"
                      :key="index"
                      :label="item.user_name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <i class="el-icon-circle-plus addbtn" @click="addInput"></i>
            </div>
          </div>
          <!-- <div class="right">
            <el-table stripe :data="list2">
              <el-table-column
                label="付款账户"
                prop="account"
                width="auto"
                align="center"
              ></el-table-column>
              <el-table-column
                label="付款金额"
                prop="amount"
                width="auto"
                align="center"
              >
                <template #default="{ row }">
                  <el-input
                    v-model="row.amount"
                    placeholder="付款金额"
                    type="number"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column
                label="付款人"
                prop="create_name"
                width="auto"
                align="center"
              ></el-table-column>
            </el-table>
          </div> -->
        </div>
      </el-form>

      <!-- 表格结束 -->
    </div>
    <!-- 底部按钮 -->
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <!-- <el-button type="success" @click="save(1)">完成入库</el-button> -->
      <el-button v-if="!isCopy" type="primary" @click="save(0)">
        完成入库
      </el-button>
      <el-button v-else @click="handlersave">保存</el-button>
      <el-button>打印</el-button>
      <el-button @click="close">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { purchaseInsetDetail } from '@/api/purchase'
  import _ from 'lodash'
  import {
    jbrSelect,
    gysSelect,
    fkzhSelect,
    addPurchaseInsetOrder,
    purchaseReview,
  } from '@/api/purchase'
  import { getAllSelect2 } from '@/api/advanceOrder'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import { postAction } from '@/api/Employee'
  export default {
    name: 'Add',
    components: {
      GoodsSearch,
    },
    // /baseAdmin/common/account
    data() {
      return {
        showBtn: 0,
        showDialog: false,
        loading: false,
        jbrList: [],
        fkrList: [],
        gysList: [],
        loadingS: false,
        gysListBK: [],
        ckList: [],
        fkzhList: [],
        allSum: 0,
        minUnit: false,
        isCopy: false,
        maxUnit: false,
        typeSelect: [
          {
            id: 1,
            value: '采购',
          },
          {
            id: 2,
            value: '赠送',
          },
        ],
        list_: [
          {
            goods_type: 1,
            goods_name: '',
            goods_specs: '',
            goods_code: '',
            arr_unit: [],
            goods_uint_change: '',
            goods_production_date: '',
            unit_id: '',
            unit_name: '',
            goods_price: '',
            goods_num: '',
            sum_money: '',
            remark: '',
          },
        ],
        pageNo: 1,
        pageSize: 50,
        layout: 'total, sizes, prev, pager, next, jumper',
        form: {
          brand_id: '',
          supp_id: '', //供应商id
          operator_id: '', //经办人id
          depot_id: '', //仓库id
          final_money: '',
          return_money: '', //退款金额
          remark: '', //备注
          auto_update_price: 1,
        },
        payInfo: {
          account_id: '',
          user_id: '',
          amount: '',
        },
        pay_info: [
          {
            account_id: '',
            account: '',
            user_id: '',
            create_name: '',
            amount: '',
          },
        ],
        // 表格
        checkList: [
          '类型',
          '商品名称',
          '规格',
          '条形码',
          '生产日期',
          '单位换算',
          '库存',
          '单位',
          '采购价',
          '采购数量',
          '金额',
          '备注',
        ],
        columns: [
          {
            order: 1,
            label: '类型',
            width: '100',
            prop: 'goods_type',
          },
          {
            order: 2,
            label: '商品名称',
            width: '300',
            prop: 'goods_name',
          },
          {
            order: 3,
            label: '规格',
            width: '',
            prop: 'goods_specs',
          },
          {
            order: 10.1,
            label: '条形码',
            width: '',
            prop: 'goods_code',
          },
          {
            order: 10.2,
            label: '生产日期',
            width: '220',
            prop: 'goods_production_date',
          },
          {
            order: 10.3,
            label: '单位换算',
            width: '',
            prop: 'goods_uint_change',
          },
          {
            order: 10.4,
            label: '库存',
            width: '',
            prop: 'balance_count_read',
          },
          {
            order: 7,
            label: '单位',
            width: '', //'80',
            prop: 'unit_name',
          },
          {
            order: 8,
            label: '采购价',
            width: '',
            prop: 'goods_price',
          },
          {
            order: 9,
            label: '采购数量',
            width: '',
            prop: 'goods_num',
          },
          {
            order: 10,
            label: '金额',
            width: '',
            prop: 'sum_money',
          },
          {
            order: 11,
            label: '备注',
            width: '',
            prop: 'remark',
          },
        ],
        list: [
          {
            goods_type: 1,
            goods_name: '',
            goods_specs: '',
            goods_code: '',
            arr_unit: [],
            goods_uint_change: '',
            goods_production_date: '',
            unit_id: '',
            unit_name: '',
            goods_price: '',
            goods_num: '',
            sum_money: '',
            remark: '',
          },
        ],
        list2: [],
      }
    },
    computed: {
      title() {
        if (this.isCopy) {
          return '编辑采购入库单'
        } else {
          return '添加采购入库单'
        }
      },
      computedTotal() {
        let l = 0
        this.list.forEach((i) => {
          if (i.goods_id) {
            l++
          }
        })
        return l
      },
      pageData: function () {
        return this.list.slice(
          (this.pageNo - 1) * this.pageSize,
          this.pageNo * this.pageSize
        )
      },
      yhl: {
        get: function () {
          return Number(this.allSum) - Number(this.form.final_money)
        },
        set: function (ne) {
          // this.yhl = 12
          console.log('1111', ne)
        },
      },
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      showDialog(val) {
        if (!val) {
          this.clearForm()
          this.pay_info = [
            {
              account_id: '',
              account: '',
              user_id: '',
              create_name: '',
              amount: '',
            },
          ]
          this.form.final_money = ''
        } else {
          if (this.isCopy) {
            this.fetchData()
          }
        }
      },
      // 总额
      allSum(val) {
        if (val) {
          this.form.final_money = val
          this.payInfo.amount = val
        }
      },
      // 按最小单位
      minUnit(val) {
        console.log(val)
        if (val) {
          this.maxUnit = false
          this.list.forEach((item) => {
            if (item.goods_name !== '') {
              // 单位变小单位
              item.unit_id = item.arr_unit.filter(
                (item2) => item2.unit_type == 1
              )[0].id
              // 编码变小编码
              item.goods_code = item.arr_unit.filter(
                (item2) => item2.id == item.unit_id
              )[0].unit_code
              //采购价变小单位采购价
              // item.goods_price = item.arr_unit.filter(
              //   (item2) => item2.id == item.unit_id
              // )[0].sell_price
              this.selectUnit(item.unit_id, item, false)
              this.priceSum('', item)
            }
          })
        }
      },
      // 按最大单位
      maxUnit(val) {
        console.log(val)
        if (val) {
          this.minUnit = false
          this.list.forEach((item) => {
            if (item.goods_id && item.goods_id != -1) {
              // 单位变小单位
              let iid = item.arr_unit.filter(
                (item2) => item2.unit_type == 3
              )[0] || { id: '' }
              item.unit_id = iid.id
              // // 编码变小编码
              let coded = item.arr_unit.filter(
                (item2) => item2.id == item.unit_id
              )[0] || { unit_code: '' }
              item.goods_code = coded.unit_code
              this.selectUnit(item.unit_id, item, false)
              // this.priceSum('', item)
            }
          })
        }
      },
    },
    created() {
      // 经办人&付款人下拉
      jbrSelect().then((res) => {
        this.jbrList = res.data.rows
        this.fkrList = res.data.rows
        this.form.operator_id = this.$store.getters['user/user_id']
      })
      // 供应商下拉
      gysSelect().then((res) => {
        this.gysList = res.data.rows
        this.gysListBK = res.data.rows
        this.form.supp_id = this.gysList[0].id
      })
      // 仓库下拉
      getAllSelect2().then((res) => {
        this.ckList = res.data
        this.form.depot_id = String(this.ckList[0].id)
      })
      // 付款账户
      // fkzhSelect().then((res) => {
      //   console.log(res)
      //   this.fkzhList = res.data
      // })
      this.fkzhanghu()
    },
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, msg, code } = await purchaseInsetDetail({
          order_id: this.id,
        })
        console.log(data)

        let dada = data.rows
        console.log(dada)
        // tips
        this.bill_code = dada.bill_code
        this.create_at = dada.create_at
        this.check_at = dada.check_at
        this.final_money = data.final_money
        // 供应商
        this.form = JSON.parse(JSON.stringify(dada))
        delete this.form.detail_info
        delete this.form.log
        this.form.depot_id = String(this.form.depot_id)
        this.form.order_id = this.id

        this.list = dada.detail_info.detail
        if (data.rows.discount_money) {
          this.yhl = data.rows.discount_money
        }
        this.totalM = data.rows.total_amount
        this.list2 = dada.log.log
        this.loading = false
      },
      fkzhanghu() {
        postAction('/baseAdmin/common/account', {}).then((res) => {
          console.log(res, '账户')
          this.fkzhList = res.data
        })
      },
      handleYhh(val) {
        this.payInfo.amount = JSON.parse(JSON.stringify(val))
      },
      inputfocus(row) {
        console.log('input focus')
        if (row.goods_num == 0) {
          row.goods_num = ''
        }
      },
      // 表格合计
      getSummaries(param) {
        const { columns, data } = param
        const sums = []
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '总价'
            return
          }
          const values = this.list.map((item) => Number(item[column.property]))
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[index] += ' 元'
          } else {
            sums[index] = 'N/A'
          }
        })
        let a = []
        sums.forEach((item, idx) => {
          if (idx == 7) {
            a.push(item)
            this.allSum = item.split(' 元')[0]
          } else if (idx == 6) {
            a.push(item.split(' 元')[0])
          } else {
            a.push('')
          }
        })
        a[0] = '合计'
        return a
      },
      // 采购价事件
      priceSum(val, row) {
        row.sum_money = (
          Number(row.goods_price) * Number(row.goods_num)
        ).toFixed(2)
      },
      close() {
        this.showDialog = false
      },
      // 表格内单位切换
      unitChange(e, row) {
        console.log(e, row)
        if (row.goods_type == 1) {
          let price = row.arr_unit.filter((item) => item.id == e)[0].sell_price
          row.goods_price = price
        }
      },

      chooseGoodsKeyword(val, row) {},
      copyRow(index, row) {
        this.list.splice(index, 0, JSON.parse(JSON.stringify(row)))
      },
      deleteRow(index, row) {
        if (this.pageData.length <= 1) {
          this.$message.error('不能删除最后一行')
        } else {
          this.list.splice(index, 1)
        }
      },
      // 表格添加一行
      addRow() {
        if (
          this.list.length == 0 ||
          this.list[this.list.length - 1].goods_name !== ''
        ) {
          this.list.push({
            goods_type: 1,
            goods_name: '',
            goods_specs: '',
            goods_code: '',
            arr_unit: [],
            goods_uint_change: '',
            goods_production_date: '',
            unit_id: '',
            unit_name: '',
            goods_price: '',
            goods_num: '',
            sum_money: '',
            remark: '',
          })
          console.log('addRow')
        }
      },
      // 商品搜索添加rows
      addRows(val, index, row) {
        console.log('addRows123')
        console.log(val)
        val.forEach((item, idx) => {
          this.$set(item, 'goods_uint_change', item.unit_cv)
          this.$set(item, 'sum_money', '')
          this.$set(item, 'goods_specs', item.specs)
          this.$set(item, 'goods_num', '')
          // item.goods_uint_change = item.unit_cv
          // item.goods_specs = item.specs
          // item.goods_num = item.quantity
          // // item.goods_uint_change = item.unit_cv
          // // item.goods_specs = item.specs
          // item.goods_num = JSON.parse(JSON.stringify(item.quantity)) || 0
          // item.sum_money = JSON.parse(JSON.stringify(item.goods_money)) || 0
          this.list.splice(index, 0, item)
          // 默认单位
          item.arr_unit.forEach((item2) => {
            if (item2.is_default == '2') {
              console.log(item2)
              item.unit_id = item2.id
              item.goods_code = item2.unit_code
              item.goods_price =
                item2.purc_pirce == 0 ? item2.cost_price : item2.purc_pirce
            }
          })
        })
      },
      moneyChange(val, row) {
        console.log('v', val)
        const money = Number(val) || 0
        if (money) row.goods_price = (money / Number(row.goods_num)).toFixed(2)
      },
      // 商品选中
      selectGoods(val, row) {
        // console.log(val, row)
        // row.goods_id = val.goods_id
        this.$set(row, 'goods_id', val.goods_id)
        row.goods_name = val.goods_name
        row.goods_uint_change = val.unit_cv
        row.goods_specs = val.specs
        row.arr_unit = val.arr_unit
        row.goods_num = ''
        row.goods_type = 1
        row.sum_money = ''
        row.balance_count_read = val.balance_count_read
        // row.unit_id = val.unit_id
        row.goods_production_date = val.goods_production_date
        // 默认单位
        row.arr_unit.forEach((item) => {
          if (item.is_default == '2') {
            console.log(item)
            row.unit_id = item.id
            row.goods_code = item.unit_code
            if (item.purc_pirce == 0) {
              row.goods_price = item.cost_price
            } else {
              row.goods_price = item.purc_pirce
            }
          }
        })
        this.addRow()
      },
      // 单位选中
      selectUnit(val, row, changeC = true) {
        if ((this.minUnit && changeC) || (this.maxUnit && changeC)) {
          this.minUnit = false
          this.maxUnit = false
        }
        // console.log(val, row)
        let coded = row.arr_unit.filter((item) => item.id == val)[0] || {
          unit_code: '',
        }
        row.goods_code = coded.unit_code

        let price = row.arr_unit.filter((item) => item.id == val)[0]
        console.log('price', price)
        let endP = ''
        if (price) {
          if (price.purc_price == 0) {
            endP = price.cost_price
          } else {
            endP = price.purc_price
          }
        }

        if (price && row.goods_type == 1) {
          row.goods_price = endP
        }
        // row.goods_price = row.arr_unit.filter(
        //   (item) => item.id == val
        // )[0].sell_price
        this.priceSum('', row)
      },
      // 添加icon
      addInput() {
        console.log(this.payInfo)
        this.pay_info.push({
          account_id: '',
          user_id: '',
          amount: '',
        })
        // if (this.payInfo.account_id != '' && this.payInfo.user_id !== '') {
        //   this.payInfo.create_name = this.fkrList.filter(
        //     (item) => item.id == this.payInfo.user_id
        //   )[0].user_name
        //   this.payInfo.account = this.fkzhList.filter(
        //     (item) => item.id == this.payInfo.account_id
        //   )[0].name
        //   this.list2.push(this.payInfo)
        //   this.payInfo = {
        //     account_id: '',
        //     user_id: '',
        //     amount: '0',
        //   }
        // } else {
        //   this.$message.error('请选择账户和付款人')
        // }
      },
      //编辑
      handlersave() {
        let list = JSON.parse(JSON.stringify(this.list))

        // 处理rows的unit_name
        list.forEach((item, index) => {
          // 处理  如果最后一行是空  去掉空row
          if (item.goods_name == '' && list.length >= 1) {
            list.splice(index, 1)
          }
          item.unit_name = item.arr_unit.filter(
            (item2) => item2.id == item.unit_id
          )[0]
          if (item.unit_name) {
            item.unit_name = item.unit_name.unit_name
          }
        })
        if (list.length == 0) {
          this.$message.error('请添加商品')
          return false
        }
        let data = {
          id: this.id,
          order_info: JSON.stringify(this.form),
          goods_info: JSON.stringify(list),
          pay_info: JSON.stringify(this.pay_info),
        }
        postAction('purchaseAdmin/base/edit-base', data).then((res) => {
          if (res.code == 200) {
            this.clearForm()
            this.$emit('refresh')
            this.$message.success(res.msg)
            this.showDialog = false
          }
        })
      },
      save(type = 0) {
        let list = JSON.parse(JSON.stringify(this.list))

        // 处理rows的unit_name
        list.forEach((item, index) => {
          // 处理  如果最后一行是空  去掉空row
          if (item.goods_name == '' && list.length >= 1) {
            list.splice(index, 1)
          }
          item.unit_name = item.arr_unit.filter(
            (item2) => item2.id == item.unit_id
          )[0]
          if (item.unit_name) {
            item.unit_name = item.unit_name.unit_name
          }
        })
        if (list.length == 0) {
          this.$message.error('请添加商品')
          return false
        }
        let data = {
          order_info: JSON.stringify(this.form),
          goods_info: JSON.stringify(list),
          pay_info: JSON.stringify(this.pay_info),
        }
        console.log(data)
        if (type == 0) {
          console.log('提交123')
          addPurchaseInsetOrder(data).then((res) => {
            console.log(res)
            if (res.code == 200) {
              this.clearForm()
              this.$emit('refresh')
              this.$message.success(res.msg)
              this.showDialog = false
            }
          })
        } else {
          console.log('提交并生成')

          purchaseReview(data).then((res) => {
            console.log(res)
            if (res.code == 200) {
              this.clearForm()
              this.$emit('refresh')

              this.$message.success(res.msg)
              this.showDialog = false
            }
          })
        }
      },
      gysChange(val) {
        if (val) {
          this.list = []
          this.addRow()
          this.form.brand_id = this.gysList.filter(
            (item) => item.id == val
          )[0].brand_ids
        } else {
          this.form.brand_id = ''
          this.list = []
          this.addRow()
        }
      },
      goodsTypeChange(row) {
        console.log(row)
        if (row.goods_type == 1) {
          const p = row.arr_unit.filter((i) => i.id == row.unit_id)[0]
          const price = p.purc_pirce
          row.goods_price = price
          row.sum_money = (Number(price) * Number(row.goods_num)).toFixed(2)
        } else {
          row.goods_price = 0
          row.sum_money = 0
        }
      },
      clearForm() {
        this.$refs.form.resetFields()
        this.minUnit = false
        this.maxUnit = false
        this.list = []
        this.addRow()
        this.list2 = []
      },
      // 供应商下拉调接口搜索
      async remoteMethod(query) {
        if (query !== '') {
          this.loadingS = true
          let res = await postAction(
            '/baseAdmin/supplier-user/window-view-do',
            { keyword: query }
          )
          console.log('res', res)
          this.gysList = res.data
          this.loadingS = false
        } else {
          this.gysList = this.gysListBK
        }
      },
      gycBlur() {
        setTimeout(() => {
          this.gysList = this.gysListBK
        }, 200)
      },
      // changeList() {
      //   console.log('changeList')
      //   console.log('每页多少条:', this.pageSize)
      //   console.log('第几页:', this.pageNo)
      //   this.list = this.list_
      //   this.list_ = this.list.slice(
      //     (this.pageNo - 1) * this.pageSize,
      //     (this.pageNo - 1) * this.pageSize + this.pageSize
      //   )
      // },
      handleCurrentChange(val) {
        this.pageNo = val
      },
      handleSizeChange(val) {
        this.pageSize = val
      },
    },
  }
</script>
<style lang="scss" scoped>
  .pay {
    display: flex;
    justify-content: flex-start;
    .right {
      width: 50%;
    }
  }
  .addbtn {
    position: absolute;
    top: 0;
    right: -30px;
    margin-left: 15px;
    font-size: 32px;
    color: #84bc39;
  }
  .el-dialog__body {
    padding: 0px 20px !important;
  }
</style>
