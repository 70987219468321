var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, inline: "" } },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "operator_id" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "180px" },
                  attrs: { clearable: "", placeholder: "请选择经办人" },
                  model: {
                    value: _vm.form.operator_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "operator_id", $$v)
                    },
                    expression: "form.operator_id",
                  },
                },
                _vm._l(_vm.jbrList, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.user_name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "supp_id" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "180px" },
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择供应商",
                  },
                  model: {
                    value: _vm.form.supp_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "supp_id", $$v)
                    },
                    expression: "form.supp_id",
                  },
                },
                _vm._l(_vm.gysList, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.supp_name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "depot_id" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "180px" },
                  attrs: { clearable: "", placeholder: "入库仓库" },
                  model: {
                    value: _vm.form.depot_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "depot_id", $$v)
                    },
                    expression: "form.depot_id",
                  },
                },
                _vm._l(_vm.ckList, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.depot_name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "bill_code" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入订单号" },
                model: {
                  value: _vm.form.bill_code,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "bill_code", $$v)
                  },
                  expression: "form.bill_code",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("goods-search", {
                ref: "goodsSearch",
                attrs: { "is-table": false },
                on: { "select-goods-all": _vm.selectGoodsId },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "pay_status" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "结算状态" },
                  model: {
                    value: _vm.form.pay_status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "pay_status", $$v)
                    },
                    expression: "form.pay_status",
                  },
                },
                _vm._l(_vm.pay_status, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.value, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "print_status" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "打印状态" },
                  model: {
                    value: _vm.form.print_status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "print_status", $$v)
                    },
                    expression: "form.print_status",
                  },
                },
                _vm._l(_vm.print_status, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.value, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-date-picker", {
                staticStyle: { width: "220px" },
                attrs: {
                  type: "daterange",
                  "range-separator": "-",
                  "value-format": "yyyy-MM-dd",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "remark" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入备注" },
                model: {
                  value: _vm.form.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "is_closed" } },
            [
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.form.is_closed,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "is_closed", $$v)
                    },
                    expression: "form.is_closed",
                  },
                },
                [_vm._v("查看作废单据")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleQuery } },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "warning" }, on: { click: _vm.handleReset } },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-button", { on: { click: _vm.handleAdd } }, [
                _vm._v("添加"),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleExport } },
                [_vm._v("导出")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          attrs: {
            stripe: "",
            data: _vm.list,
            "show-summary": "",
            "summary-method": _vm.summaryFunction,
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", type: "index", width: "50" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", type: "selection", width: "50" },
          }),
          _vm._l(_vm.finallyColumns, function (item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: {
                prop: item.prop,
                label: item.label,
                width: "auto",
                align: "center",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        item.label == "单号"
                          ? _c(
                              "div",
                              {
                                staticClass: "underline",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleCheck(row)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(row[item.prop]) + " ")]
                            )
                          : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c("el-table-column", {
            attrs: {
              prop: "",
              align: "center",
              label: "操作",
              fixed: "right",
              "min-width": "150px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var $index = ref.$index
                  var row = ref.row
                  return [
                    row.status_text == "已作废"
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.handleCheck(row)
                                  },
                                },
                              },
                              [_vm._v(" 查看 ")]
                            ),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.handleCheck(row)
                                  },
                                },
                              },
                              [_vm._v(" 查看 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.handleEdit($index, row)
                                  },
                                },
                              },
                              [_vm._v(" 编辑 ")]
                            ),
                            row.can_scrap == 1 &&
                            _vm.authbutton[3].is_check == 1
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.handleDelete(row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 作废 ")]
                                )
                              : _vm._e(),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.handlePrint($index, row)
                                  },
                                },
                              },
                              [_vm._v(" 打印 ")]
                            ),
                          ],
                          1
                        ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.form.pageNo,
          layout: _vm.layout,
          "page-size": _vm.form.pageSize,
          total: _vm.total,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("check", { ref: "check" }),
      _c("add", { ref: "add", on: { refresh: _vm.fetchData } }),
      _c("ruilang-display-dialog", {
        ref: "RuilangDisplayDialog",
        attrs: { bill_type: "CG", type: 1, data_id: _vm.data_id },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }